/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';
import logo from 'assets/images/hero/icon.png';
import Button from '../elements/Button';

export default function BrandIcon() {
  return (
    <Button className="flex items-center" type="link" href="/">
      <img
        src={logo}
        alt="Chitragupta Logo"
        className="w-20 h-20 mr-4 transform hover:scale-105 rounded-[15%] clip-path-[polygon(50%_0%,100%_12.5%,100%_37.5%,87.5%_100%,50%_87.5%,12.5%_100%,0%_37.5%,0%_12.5%)]"
      />
      <p className="text-theme-blue text-4xl font-medium">
        Chitra
        <span className="text-orange-600">gupta</span>
      </p>
    </Button>
  );
}
