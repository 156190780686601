/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-boolean-value */
// eslint-disable-next-line react/no-array-index-key
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import { Fade } from 'react-awesome-reveal';

import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function About() {
  return (
    <section className="container mx-auto">
      <br />
      <br />
      <br />
      <br />
      <br />
      <Fade direction="up">
        <h1 className="text-5xl text-theme-blue text-center font-bold">Our Mission and Vision</h1>
      </Fade>
      <br />
      <br />
      <Fade direction="up" delay={500}>
      <p className="font-light text-2xl text-gray-800 text-center max-w-6xl mx-auto mb-3 sm:mb-3 xl:mb-4">
        Chitragupta is on a mission to use data to revolutionize urban living and 
        governance through scalable, AI-powered data systems. 
        </p>
        <p className="font-light text-xl text-gray-400 text-center max-w-6xl mx-auto mb-3 sm:mb-3 xl:mb-4">
        We envision cities 
        where data drives efficiency, safety, and sustainability.
        Chitragupta is a planetary scale sensory data company.  
        We observe the world through vision, audio & other sensors, and 
        make it useful.
        </p>
      </Fade>
    </section>
  );
}
