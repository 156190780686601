/* eslint-disable import/extensions */

// Services Img Imports
import S1 from '../assets/images/Services/S1.png';
import S2 from '../assets/images/Services/S2.png';
import S3 from '../assets/images/Services/S3.png';
import S4 from '../assets/images/Services/S4.png';

// Portfolio Img Imports
import P1 from '../assets/images/Portfolio/P1.mp4';
import P2 from '../assets/images/Portfolio/P2.mp4';
import P3 from '../assets/images/Portfolio/P3.mp4';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.jpg';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.jpg';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.jpg';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.jpg';

export const Services = [
  {
    title: 'Municipal and Traffic Planning',
    imageUrl: S1,
    animation: 'left',
    description: 'Empower governments to plan Municipal and Traffic laws to the last mile of the city.',
  },
  {
    title: 'City Planning',
    imageUrl: S2,
    animation: 'up',
    description: 'Create dynamic digital twins of urban spaces to aid in infrastructure development, road safety, and resource allocation.',
  },
  {
    title: 'Points of Interest',
    imageUrl: S3,
    animation: 'up',
    description: 'Support businesses with accurate data for storefront verification, loan risk assessment, and more.',
  },
  {
    title: 'Orthagonal Applications',
    imageUrl: S4,
    animation: 'right',
    description: 'Training datasets for autonomous vehicles, extended sensor networks, and global city monitoring.',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uasdads1',
    title: 'Driving Behaviour',
    imageUrl: P1,
    videoUrl: P1,
    type: '',
    responsibility: [],
    // credit: 'https://dribbble.com/shots/15164950-Recruiting-app',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Mapped Streets',
    imageUrl: P2,
    videoUrl: P2,
    type: '',
    responsibility: [],
    // credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Traffic Clusters Over Time',
    imageUrl: P3,
    videoUrl: P3,
    type: '',
    responsibility: [],
    // credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
];

export const Advantages = [
  [{
    title: 'Comprehensive Data',
    description: 'We provide comprehensive detailed data insights as much as possible.',
    imageUrl: Communicative,
  },
  {
    title: 'Insightful Data',
    description: 'We leverage our latest AI/ML tech powered solution to provide useful insights.',
    imageUrl: Management,
  }],
  [{
    title: 'Frequently Updated Data',
    description: 'We provide insights on data with the best refresh rate in the industry.',
    imageUrl: Collaborative,
  },
  {
    title: 'Mission Driven',
    description: "We aim to revolutionize urban living and governance with scalable, AI-powered data systems.",
    imageUrl: Favorite,
  }],
];

export const TeamMembers = [
  {
    name: 'Rach David',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Pauline Sydney',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Granger Watterson',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'Tom Jimmy',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Jim Hendrix',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Calvin Max',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Hawkins Jim',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Don Bizaro',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Bill Markinson',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Igor Kavarov',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Freddie Curl',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Monica Lovegood',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];
