/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */
import React from "react";

import { Fade } from "react-awesome-reveal";

import cgVideoKepler from "../assets/images/hero/hero.mp4";

export default function Hero() {
  return (
    <section className="hero">
      <div className="w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8">
        <h1 className="text-5xl sm:text-6xl text-theme-blue font-bold leading-tight mb-5">
          Bringing Bharat to the Internet
        </h1>

        <p className="font-light text-xl text-gray-500 leading-relaxed mb-1">
        {`Chitragupta is mapping Bharat's roads and streets, visually so, one street at a time,
        till the last mile with the best refresh rate in the industry.`} </p><br />
        <p className="font-light text-l text-gray-400 leading-relaxed mb-10">
        We then train our ML models on this proprietory data to generate intelligence and metadata.
        This data is used to building interesting applications ranging from B2B,
         B2C and even B2G use cases.
         </p>
        
      </div>
      <div className="flex pt-5 md:w-fit w-full justify-center items-center order-first md:order-first lg:order-last lg:w-1/2">
        <Fade direction="right" triggerOnce>
          {/* <img className="" src={BuildWebsite} alt="Build Website" /> */}
          <div className="w-100 h-150 rounded-lg overflow-hidden  relative bg-white shadow-[0_15px_30px_rgba(0,0,0,0.9),0_10px_20px_rgba(0,0,0,0.7)]">
            <video autoPlay muted loop className="w-100 h-full object-cover scale-[1.1]">
              <source src={cgVideoKepler} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Fade>
      </div>
    </section>
  );
}
