/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import BrandIcon from './BrandIcon';
import Button from '../elements/Button';
import linkedin from "../assets/images/hero/linkedin.png";

export default function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [privacyPolicyContent, setPrivacyPolicyContent] = useState('');

  // Open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Fetch the privacy policy content from the text file
  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await fetch('/assets/txt/privacyPolicy.txt');
        const data = await response.text();
        setPrivacyPolicyContent(data);
      } catch (error) {
        // eslint-disable-next-line
        console.error('Error fetching privacy policy:', error);
      }
    };

    if (isModalOpen) {
      fetchPrivacyPolicy();
    }
  }, [isModalOpen]);

  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto">
        <div className="flex flex-col lg:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Contact</h1>
            <p className="text-lg text-gray-400 font-light">
              Email : admin@chitragupta.co
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>

            {/* Company LinkedIn */}
            <Button
              href="https://www.linkedin.com/company/chitragupta-urban-tech/"
              type="link"
              target="_blank"
              className="flex items-center justify-start text-gray-400 hover:text-blue-600"
              isExternal
            >
              <img src={linkedin} alt="LinkedIn" className="w-6 h-6 mr-2" />
              Chitragupta Urban Tech (Company)
            </Button>

            {/* Co-founder 1 LinkedIn */}
            <Button
              href="https://www.linkedin.com/in/namanpaithankar"
              type="link"
              target="_blank"
              className="flex items-center justify-start text-gray-400 hover:text-blue-600 mt-2"
              isExternal
            >
              <img src={linkedin} alt="LinkedIn" className="w-6 h-6 mr-2" />
              Naman Paithankar (Co-Founder and CEO)
            </Button>

            {/* Co-founder 2 LinkedIn */}
            <Button
              href="https://www.linkedin.com/in/jayeshgolatkar"
              type="link"
              target="_blank"
              className="flex items-center justify-start text-gray-400 hover:text-blue-600 mt-2"
              isExternal
            >
              <img src={linkedin} alt="LinkedIn" className="w-6 h-6 mr-2" />
              Jayesh Golatkar (Co-Founder and CTO)
            </Button>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gradient-to-r from-purple-50 via-pink-50 to-orange-50 p-6 rounded-2xl w-4/5 max-w-3xl h-auto max-h-[90%] overflow-hidden transform transition-all duration-300 ease-in-out shadow-lg shadow-gray-500/50">
            <h2 className="text-3xl font-bold text-center text-theme-blue mb-6">
              Privacy Policy
            </h2>
            <div className="overflow-y-auto max-h-[60vh] px-4 py-2 text-lg text-gray-800">
              {/* Display the privacy policy content */}
              <p className="whitespace-pre-line">{privacyPolicyContent}</p>
            </div>
            
            {/* Scroll Icon */}
            <div className="flex justify-center mt-4">
              <FaChevronDown className="text-gray-500 animate-bounce" />
            </div>
            
            {/* Ok Button */}
            <div className="text-center mt-4">
              <button
                type="submit"
                onClick={closeModal}
                className="bg-blue-600 text-white px-8 py-3 rounded-xl hover:bg-blue-700 transition-colors duration-300"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
        )}

        <div className="flex-col text-center mt-7">
          <p className="text-sm text-gray-400 font-light">
            Copyright 2024 - All rights reserved - Chitragupta.co
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Made in Bharat 🇮🇳 by&nbsp;
            </p>
            <Button
              href="https://www.linkedin.com/company/chitragupta-urban-tech/"
              type="link"
              target="_blank"
              className="text-m text-orange-600 font-light"
              isExternal
            >
              Chitragupta.co
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={openModal}
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Privacy Policy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
