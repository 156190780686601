/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from 'react';

import { Fade } from 'react-awesome-reveal';

export default function Service({ data }) {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-5xl text-theme-blue text-center font-bold">Solutions & Applications</h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            {`Transforming Bharat's cities to be citizen first, 
            one street and one application at a time.`}
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-4 sm:grid-rows-1 ms:gap-6 lg:grid-cols-4 lg:grid-rows-1 lg:gap-6">
          {
            data.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fade direction={item.animation} delay={500 * index} key={index} triggerOnce>
                <div>
                <div className="bg-white group rounded-2xl shadow-2xl border border-light-theme-orange transform transition duration-500 hover:scale-105 h-96">
                  <img src={item.imageUrl} alt="Service" className="w-full h-56 object-cover rounded-t-2xl" />
                  <h2 className="text-theme-blue text-center text-l py-2 rounded-b-2xl">{item.title}</h2>
                  <p className="text-black-100 text-center text-xs py-7 px-4 rounded-b-2xl text-justify whitespace-normal">{item.description}</p>
                </div>
                </div>
              </Fade>
            ))
          }
        </div>
      </div>
    </div>
  );
}
