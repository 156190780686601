/* eslint-disable linebreak-style */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Button from '../elements/Button';

export default function Portfolio({ data }) {
  return (
    <section className="container mx-auto flex flex-col items-center mt-20">
      <Fade direction="right" triggerOnce>
        <h1 className="text-5xl text-theme-blue text-center font-bold">How we see the world</h1>
      </Fade>
      <Fade direction="left" triggerOnce>
        <p className="font-light text-lg text-gray-400 text-center mb-12">
          We observe the world through vision, audio & other sensors, and make it useful.
        </p>
      </Fade>

      <div className="grid grid-cols-2 sm:grid-cols-3 sm:gap-2 xl:gap-8 justify-items-center">
        {
          data.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fade direction="up" triggerOnce bottom delay={500 * index} key={index}>
              <Button type="link" href="/">
                <div className="group rounded-2xl shadow-xl w-auto m-3 transform transition duration-500 hover:scale-110 portofolio-card border border-light-theme-orange">
                  <div className="relative">
                    <div className="rounded-t-2xl z-0">
                      <video autoPlay muted loop className="rounded-t-2xl z-0">
                        <source src={item.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="py-4">
                    <h2 className="text-theme-blue text-center text-l">{item.title}</h2>
                    <p className="font-light text-gray-400 text-center">{item.type}</p>
                  </div>
                </div>
              </Button>
            </Fade>
          ))
        }
      </div>
    </section>
  );
}
